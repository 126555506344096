// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kC";
export var caseStudyBackgroundContainer = "bl_kF";
export var caseStudyBackgroundContainer__ship = "bl_kG";
export var caseStudyBackground__bgColor = "bl_ky";
export var caseStudyBackground__lineColor = "bl_kz";
export var caseStudyHead__imageWrapper = "bl_kv";
export var caseStudyProjectsSection = "bl_kD";
export var caseStudyQuote__bgLight = "bl_kK";
export var caseStudyQuote__bgRing = "bl_kx";
export var caseStudySolution__ring = "bl_kH";
export var caseStudySolution__ringTwo = "bl_kJ";
export var caseStudyTech = "bl_kL";
export var caseStudy__bgDark = "bl_ks";
export var caseStudy__bgLight = "bl_kr";